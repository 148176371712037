<template>
	<div>
		<channels :channel="channel"></channels>
	</div>
</template>

<script>
export default {
  name: 'CreateChannel',
  components:{
    Channels: () => import('@/components/ad/Channels')
  },
  data(){
    return{
      channel: {platform: 'Telegram'},
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
